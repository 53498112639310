import React, { useEffect, useState } from "react";
import Link from "next/link";
import Image from "next/image";

import HeaderRightTwo from "../Header-Right/HeaderRight-Two";
import Search from "../Offcanvas/Search";
import Nav from "../Nav";

import logo from "../../../public/images/logo/header-logo.png";
import Category from "../Category/Category";
import StorageParams from "@/constants/StorageParams";
import { UserSessionUtils } from "@/utils/UserSessionUtils";
import { useAppContext } from "@/context/Context";
import { useSelector } from "react-redux";
import { useRouter } from "next/router";

const HeaderEight = ({
  headerType,
  gapSpaceBetween,
  sticky,
  headerSticky,
  navigationEnd,
  container,
}) => {
  const [isSticky, setIsSticky] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState(false);
  const [username, setUsername] = useState(null);

  const { mobile, setMobile, search, setSearch, cartToggle, setCart } = useAppContext();
  const { total_items } = useSelector((state) => state.CartReducer);

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY;
      setIsSticky(scrolled > 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const isLoggedIn = localStorage.getItem(StorageParams.IS_LOGGED_IN) === "true";
      setLoggedInUser(isLoggedIn);
      if (isLoggedIn) {
        const user = UserSessionUtils.getUserDetails();
        // console.log("User details " + JSON.stringify(user))
        setUsername((user?.firstName + " " + user?.lastName) || "User");
      }
    }
  }, []);

  return (
    <div
      className={`rbt-header-wrapper ${gapSpaceBetween} ${sticky} ${!headerType && isSticky ? headerSticky : ""
        }`}
    >
      <div className={`${container}`}>
        <div className={`mainbar-row ${navigationEnd} align-items-center`}>
          <div className="header-left rbt-header-content">
            <div className="header-info">
              <div className="logo">
                <Link href="/">
                  <Image
                    src={logo}
                    width={250}
                    height={100}
                    priority={true}
                    alt="Education Logo Images"
                  />
                </Link>
              </div>
            </div>

            <div className="header-info d-none d-lg-block">
              <Category />
            </div>
          </div>

          <div className="rbt-main-navigation d-none d-xl-block">
            <Nav />
          </div>
          <div className="header-right">
            <ul className="quick-access">
              <li className="access-icon">
                <Link
                  className={`search-trigger-active rbt-round-btn ${search ? "" : "open"}`}
                  href="#"
                  onClick={() => setSearch(!search)}
                >
                  <i className="feather-search"></i>
                </Link>
              </li>

              <li className="access-icon rbt-mini-cart">
                <Link
                  className="rbt-cart-sidenav-activation rbt-round-btn"
                  href="#"
                  onClick={() => setCart(!cartToggle)}
                >
                  <i className="feather-shopping-cart"></i>
                  <span className="rbt-cart-count">{total_items}</span>
                </Link>
              </li>

              {loggedInUser ? (
                <>
                  <li className="account-access rbt-user-wrapper d-none d-xl-block">
                    <Link href="#">
                      <i className="feather-user"></i>
                      {username}
                    </Link>
                    <User />
                  </li>

                  <li className="access-icon rbt-user-wrapper d-block d-xl-none">
                    <Link className="rbt-round-btn" href="#">
                      <i className="feather-user"></i>
                    </Link>
                    <User userDetails={UserSessionUtils.getUserDetails()} />
                  </li>
                </>
              ) : (
                <div className="rbt-btn-wrapper d-none d-xl-block" style={{ marginLeft: 50 }}>
                  <Link
                    className="rbt-btn rbt-marquee-btn marquee-auto btn-border-gradient radius-round btn-sm hover-transform-non"
                    href="/register"
                  >
                    <span data-text="Enroll Now">Enroll Now</span>
                  </Link>
                </div>
              )}
            </ul>

            <div className="mobile-menu-bar d-block d-xl-none">
              <div className="hamberger">
                <button
                  className="hamberger-button rbt-round-btn"
                  onClick={() => setMobile(!mobile)}
                >
                  <i className="feather-menu"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Search />
    </div>
  );
};

const User = ({ userDetails }) => {

  const router = useRouter();

  const handleLogout = () => {
    if (typeof window !== "undefined") {
      // Clear session storage and local storage
      localStorage.removeItem(StorageParams.IS_LOGGED_IN);
      sessionStorage.clear();
      // Redirect to the login page
      router.push('/login');
    }
  };


  const UserData = {
    "user": [
      {
        "name": "Rafi Dev",
        "img": "/images/team/avatar.jpg",
        "userList": [
          {
            "icon": "feather-home",
            "text": "My Dashboard",
            "link": "/student/student-dashboard"
          },
          {
            "icon": "feather-shopping-bag",
            "text": "Enrolled Courses",
            "link": "/student/student-enrolled-course"
          },
          {
            "icon": "feather-heart",
            "text": "Wishlist",
            "link": "/student/student-wishlist"
          },
          {
            "icon": "feather-star",
            "text": "Reviews",
            "link": "/student/student-reviews"
          },
          {
            "icon": "feather-list",
            "text": "My Quiz Attempts",
            "link": "/student/student-quiz-attempts"
          },
          {
            "icon": "feather-clock",
            "text": "Order History",
            "link": "/student/student-order-history"
          }
        ]
      }
    ]
  }


  return (
    <div className="rbt-user-menu-list-wrapper">
      {UserData &&
        UserData.user.map((person, index) => (
          <div className="inner" key={index}>
            <div className="rbt-admin-profile">
              <div className="admin-thumbnail">
                <Image
                  src={person.img}
                  width={43}
                  height={43}
                  alt="User Images"
                />
              </div>
              <div className="admin-info">
                <span className="name">{userDetails?.firstName} {userDetails?.lastName}</span>
                <Link
                  className="rbt-btn-link color-primary"
                  href="/student/student-profile"
                >
                  View Profile
                </Link>
              </div>
            </div>
            <ul className="user-list-wrapper">
              {person.userList.map((list, innerIndex) => (
                <li key={innerIndex}>
                  <Link href={list.link}>
                    <i className={list.icon}></i>
                    <span>{list.text}</span>
                  </Link>
                </li>
              ))}
            </ul>
            <hr className="mt--10 mb--10" />
            <ul className="user-list-wrapper">
              <li>
                <Link href="/student/student-settings">
                  <i className="feather-settings"></i>
                  <span>Settings</span>
                </Link>
              </li>
              <li>
                <button onClick={handleLogout} style={{ fontSize: '14px', padding: "5px 12px", background: 'none', border: 'none', cursor: 'pointer' }}>
                  <i className="feather-log-out"></i>
                  Logout
                </button>

                {/* <Link href="/">
                  <i className="feather-log-out"></i>
                  <span>Logout</span>
                </Link> */}
              </li>
            </ul>
          </div>
        ))}
    </div>
  );
};


export default HeaderEight;